import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import config from '../../../../BLL/config';
import { getDomain, host, getToken } from "../../../../BLL/environment";
import { FormattedMessage } from 'react-intl';
import { GoogleMap, useJsApiLoader, Marker, MarkerClusterer } from '@react-google-maps/api';
import { lastIcon } from '../../../../icons/trackerIcons';
import Loading from '../../../../icons/Loading';
import style from './clientLocation.module.scss';
import MapMarker from './ClientLocationMapMarker';

const ClientLocationMap = ({ selectedCompany }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.googleMapsApiKey
  });
  const [map, setMap] = useState(null);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedClient, setSelectedClient] = useState(null)
  const [hoveredClient, setHoveredClient] = useState(null);
  const [filter, setFilter] = useState('');
  const initialLoad = useRef(true);
  const mapRef = useRef(null);

  useEffect(() => {
    if(selectedCompany) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://${getDomain()}${host()}/api/client-locations/?group_id=${selectedCompany}`, {
            headers: { Authorization: `Token ${getToken()}` },
          });
          setClients(response.data);
          setLoading(false);
          if (mapRef.current && initialLoad.current) {
            const bounds = new window.google.maps.LatLngBounds();
            response.data.forEach(client => {
              if (client.lat && client.lng) {
                bounds.extend({ lat: client.lat, lng: client.lng });
              }
            });
            mapRef.current.fitBounds(bounds);
            initialLoad.current = false;
          }
        } catch (error) {
          console.error('Error fetching client locations:', error);
          setLoading(false);
        }
      };
      
      initialLoad.current = true;
      setSelectedClient(null);
      fetchData();
      
      const interval = setInterval(fetchData, 10000);
      return () => clearInterval(interval);
    }
  }, [selectedCompany]);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  const getLatestTimestamp = (client) => {
    const timestamps = [
      client.timestamp,
      client.general_timestamp,
      client.wifi_timestamp
    ]

    return Math.max(...timestamps);
  };

  const isGps = (client) => {
    const latestTimestamp = getLatestTimestamp(client);
    return latestTimestamp !== client.wifi_timestamp;
  };

  const handleClientClick = (client) => {
    setSelectedClient(client);
    if (mapRef.current && client.lat && client.lng) {
      mapRef.current.panTo({ lat: parseFloat(client.lat), lng: parseFloat(client.lng) });
      mapRef.current.setZoom(16);
    }
  };

  const resetZoom = () => {
    if (mapRef.current && clients.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      clients.forEach(client => {
        if (client.lat && client.lng) {
          bounds.extend({ lat: client.lat, lng: client.lng });
        }
      });
      mapRef.current.fitBounds(bounds);
      setSelectedClient(null);
      setHoveredClient(null);
    }
  }

  const formatDate = (timestamp) => {
    if (!timestamp) {
      return "Loc não enviada";
    }
    const date = new Date(timestamp * 1000);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} - ${hours}:${minutes}`;
  };

  const filteredClients = useMemo(() => {
    return clients.filter((client) => 
      !client.is_deleted &&
      (client.account.toLowerCase().includes(filter) ||
      `${client.first_name.toLowerCase()} ${client.last_name.toLowerCase()}`.includes(filter))
    );
  }, [clients, filter]);
  
  if (loading && !isLoaded) {
    return <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />;
  }

  return (
    <div className={style.container}>
      <div className={style.btnContainer}>
        
        <button onClick={() => resetZoom()} className={style.btn}>
          <span>RESETAR ZOOM</span>
        </button>
      </div>
      <div className={style.clientLocations}>
        <div className={style.tableContent}>
          <input
            type="text"
            className="form-control"
            value={filter}
            placeholder="Procurar por usuário ou conta"
            onChange={(e) => setFilter(e.target.value.toLowerCase())}
          />
          <div className={style.table}>
            <div className={style.header}>
              <div className={style.headerAccount}> Conta </div>
              <div className={style.headerTextContainer}> Nome </div>
              <div className={style.headerTextContainer}> Data Último Envio </div>
            </div>
            <div className={style.body}>
              {filteredClients.map((client) => (
                <div
                  key={client.id}
                  className={`${style.row} ${client.lat && style.clickableRow} ${selectedClient?.id === client.id && style.rowHovered}`}
                  onMouseEnter={() => setHoveredClient(client)}
                  onMouseLeave={() => setHoveredClient(null)}
                  onClick={() => handleClientClick(client)}
                >
                  <div className={style.bodyAccount}>{client.account}</div>
                  <div className={style.nameTextContainer}>{client.first_name.split(' ')[0]} {client.last_name.split(' ').pop()}</div>
                  <div className={style.bodyTextContainer}>{formatDate(getLatestTimestamp(client))}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <GoogleMap
          mapContainerClassName={style['map-container']}
          // center={center}
          zoom={16}
          onLoad={map => (mapRef.current = map)}
          onUnmount={onUnmount}
          options={{
            disableDefaultUI: true,
            clickableIcons: false
          }}
        >
          <MarkerClusterer>
            {(clusterer) => 
              clients.map(client => (
                client.lat && client.lng && (
                  <MapMarker
                    key={client.id}
                    clusterer={clusterer}
                    isHovered={hoveredClient?.id === client.id}
                    client={client}
                    selectedClient={selectedClient}
                    setSelectedClient={setSelectedClient}
                    isGps={isGps(client)}
                  />
                )
              ))
            }
          </MarkerClusterer>
          {hoveredClient?.lat && hoveredClient?.lng && (
            <Marker
              key={`hovered-${hoveredClient.id}`}
              position={{ lat: hoveredClient.lat, lng: hoveredClient.lng }}
              icon={lastIcon}
            />
          )}
          {selectedClient?.lat && selectedClient?.lng && (
            <Marker
              key={`selected-${selectedClient.id}`}
              position={{ lat: selectedClient.lat, lng: selectedClient.lng }}
              icon={lastIcon}
            />
          )}
        </GoogleMap>
      </div>
    </div>
  )
};

ClientLocationMap.propTypes = {
  selectedCompany: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ClientLocationMap;
