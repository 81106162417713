import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getDomain, host, getToken } from '../../../BLL/environment';
import AttendHeader from './ClosedComponents/AttendHeader';
import AttendSolvedBy from './ClosedComponents/AttendSolvedBy';
import AttendContacted from './ClosedComponents/AttendContacted';
import AttendInformation from './ClosedComponents/AttendInformation';
import AttendComments from './ClosedComponents/AttendComments';
import AttendReminders from './ClosedComponents/AttendReminders';
import AttendDetail from './OpenComponents/AttendDetail';
import AttendMap from './OpenComponents/AttendMap';

import messages from "../../../BLL/locale/messages";
import * as attendsActions from '../../../actions/attendsActions';
import * as contactsActions from '../../../actions/contactsActions';

import '../../../css/AttendanceClosed.scss';
import AttendAudioInformation from './ClosedComponents/AttendAudioInformation';
import AccordionSection from './Accordion';
import ModalDraggable from '../../Modal/ModalDraggable';
import AttendExtensionCall from './OpenComponents/AttendExtensionCall';

const AttendClosed = (props) => {
  const { contactList } = props;
  const [attend, setAttend] = useState(undefined);
  const [secondary, setSecondary] = useState(undefined);
  const [client, setClient] = useState(undefined);
  const [contacts, setContacts] = useState([]);
  const [transmitterList, setTransmitterList] = useState([]);
  const [successAttendant, setSuccessAttendant] = useState(undefined);
  const [successContact, setSuccessContact] = useState(undefined);
  const [equipment, setEquipment] = useState(undefined);
  const [contactedAttendants, setContactedAttendants] = useState([]);
  const [contactedContacts, setContactedContacts] = useState([]);
  const [remindersList, setRemindersList] = useState([]);
  const [pointList, setPointList] = useState([]);
  const [clientMessages, setClientMessages] = useState([]);
  const [report, setReport] = useState('');
  const [tagId, setTagId] = useState('');;
  const [attendAudio, setAttendAudio] = useState('');
  const [errorComponent, setErrorComponent] = useState('');
  const [editingComments, setEditingComments] = useState(false);
  const [editingTag, setEditingTag] = useState(false);
  const [currentServerTime, setCurrentServerTime] = useState(undefined);
  const [showExtensionCall, setShowExtensionCall] = useState(false);
  const [canMakeExtension, setCanMakeExtension] = useState(false);

  useEffect(() => {
    fetchAttend(props.match.params.id);
  }, []);

  useEffect(() => {
    if (attend) {
      setReport(attend.report);

      if (attend.audio_file) {
        fetchAudio(attend.id);
      }

      fetchAttendReminders(attend.id);
    }
  }, [attend]);

  useEffect(() => {
    const hasEventType606 = (att) => {
      return att.events.some(event => event.event_type.code === '606');
    }
    if (attend) {
      const serverTime = new Date(currentServerTime);
      const attendClose = new Date(attend?.close_ts);
      const difference = (serverTime - attendClose) / (1000 * 60 * 60);
      setCanMakeExtension(difference < 24 && hasEventType606(attend));
    }
  }, [attend, currentServerTime]);

  useEffect(() => {
    if (client && attend) {
      const equipmentName = equipment.name.toLowerCase();
      if (equipmentName === 'igo' || equipmentName === 'ihelp' || equipmentName === 'climax - mobile' || equipmentName === 'ev07b' || equipmentName === 'ev07b - cb') {
        fetchClosedPoints(client.id, attend.id);
      }
      if (equipment.name.toLowerCase() === 'contactto sos - app') {
        fetchSOSAppAttendPoint(attend.id);
      }
    }
  }, [client, equipment]);

  useEffect(() => {
    if (client) {
      props.loadContacts(client.id, 'priority')
    }
  }, [client]);

  useEffect(() => {
    if (client && contactList.length > 0) {
      const haveContactList = contactList.some((c) => c.clientId === client.id);
      if (haveContactList) {
        const filteredContacts = contactList.filter(
          (c) =>
            parseInt(c.clientId, 10) === client.id && c.ordering === "priority"
        )[0];
        setContacts([...filteredContacts.data]);
      }
    }
  }, [contactList, client]);

  const fetchAttend = (id) => {
    console.log('FETCHING ATTEND');
    axios
      .get(`https://${getDomain()}${host()}/api/attend/${id}/`, {
        headers: { Authorization: `Token ${getToken()}` },
      })
      .then((res) => {
        if (!res.data.attend.close_ts) {
          props.history.push(`/app/attends/call/${this.props.match.params.id}/`);
        }
        const data = res.data;
        const client = data.client;
        setClient(Object.assign({}, client));
        setAttend(Object.assign({}, data.attend));
        setEquipment(Object.assign({}, data.equipment));
        setTransmitterList(data.transmitters);
        setClientMessages(data.client_message);
        setContactedAttendants(data.contacted_att);
        setContactedContacts(data.contacted_cont);
        setTagId(data.attend.tag_id);
        setCurrentServerTime(data.current_time);

        if (client.secondary.length > 0) {
          const secondary = client.secondary[0]
          setSecondary(Object.assign({}, secondary));
        }

        if (data.success_attendant) {
          setSuccessAttendant(data.success_attendant);
        }
        if (data.success_contact) {
          setSuccessContact(data.success_contact);
        }
      });
  };

  const fetchAudio = (id) => {
    axios
      .get(`https://${getDomain()}${host()}/api/attends/audio/${id}/`, {
        headers: { Authorization: `Token ${getToken()}` },
      })
      .then((res) => {
        if (res.data.success) {
          setAttendAudio(res.data.success);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  };

  const fetchAttendReminders = (id) => {
    axios
      .get(`https://${getDomain()}${host()}/api/reminders/?attend=${id}`, {
        headers: { Authorization: `Token ${getToken()}` },
      })
      .then((res) => {
        const reminders = res.data.data;
        setRemindersList(reminders);
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const fetchClosedPoints = (clientId, attendId) => {
    axios({
      url: `https://${getDomain()}${host()}/api/clients/${clientId}/tracker-iot/attend/${attendId}/`,
      method: 'GET',
      responseType: 'json',
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    })
    .then((res) => {
      if (res.data.length > 0) {
        setPointList(res.data);
        setErrorComponent('');
      }
    })
    .catch((err) => {
      if (err.response.data.error && err.response.data.error === 'No Points') {
        const errorMessage = 'COORDENADAS NÃO ENCONTRADAS PARA ESTE ATENDIMENTO.';
        const newErrorComponent = <FormattedMessage id="attend.closed.errorNoPoints" defaultMessage={errorMessage} />;
        setErrorComponent(newErrorComponent);
      } else if (err.response.data.imei) {
        const errorMessage = 'ESTE USUÁRIO NÃO ESTÁ COM O IMEI CADASTRADO.\n';
        const newErrorComponent = <FormattedMessage id="client.tracker.errorNoIMEI" defaultMessage={errorMessage} />;
        setErrorComponent(newErrorComponent);
      }
    });
  };

  const fetchSOSAppAttendPoint = (attendId) => {
    axios({
      url: `https://${getDomain()}${host()}/api/attend/${attendId}/app-location/`,
      method: 'GET',
      responseType: 'json',
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    })
    .then((res) => {
      if (res.data) {
        const point = Object.fromEntries(
          Object.entries(res.data).map(([k, v]) => [k.toUpperCase(), v]),
        );
        setPointList([point]);
        setErrorComponent('');
      }
    })
    .catch((err) => {
      if (err.response.data && err.response.data.error.includes('No coordinates')) {
        const errorMessage = 'COORDENADAS NÃO ENCONTRADAS PARA ESTE ATENDIMENTO.';
        const newErrorComponent = <FormattedMessage id="attend.closed.errorNoPoints" defaultMessage={errorMessage} />;
        setErrorComponent(newErrorComponent)
      }
    });
  };

  const updateTag = () => {
    const tag = Object.assign({}, { tag: tagId });
    axios({
      method: 'put',
      responseType: 'json',
      url: `https://${getDomain()}${host()}/api/attend/${props.match.params.id}/tag-update/`,
      data: tag,
      headers: { Authorization: `Token ${getToken()}` },
    })
    .then(() => {
      setEditingTag(false);
    })
    .catch((error) => {
      console.log(error.response);
    })
  };

  const updateReport = () => {
    const updatedReport = Object.assign({}, { id: props.match.params.id }, { report });
    setEditingComments(false);
    props.editAttend(updatedReport);
  }

  if (!attend || !client || !equipment) {
    return (
      <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
        Carregando atendimento
      </div>
    );
  };

  const setTag = (ev) => {
    const value = ev.target.value;
    setTagId(value);
  }

  const duration = attend ? `${attend.call_duration.split(':')[1]}:${attend.call_duration.split(':')[2]}` : '00:00';
  const aditionalAudio = attend.aditional_audio.length > 0
    ? attend.aditional_audio.filter((audio) => !audio.audio_file.includes('reminder'))
    : [];
  const reminderAudio = attend.aditional_audio.length > 0
  ? attend.aditional_audio.filter((audio) => audio.audio_file.includes('reminder'))
  : [];

  console.log('Can make extension call?', canMakeExtension);

  return (
    <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
      {showExtensionCall &&
        <ModalDraggable closeModal={() => setShowExtensionCall(false)} headerTitle={messages[props.lang]['extension.headerTitle']}>
          <AttendExtensionCall
            client={client}
            contacts={contacts}
            attendId={attend.id}
            clientAccount={client.account}
            startCall={props.startCall}
            hangupSip={props.hangupSip}
            sipEventType={props.sipEventType}
          />
        </ModalDraggable>
      }
      {attend && (
        <div
          className="detail-container detail-container--100 container-fluid"
          style={{ overflow: 'hidden' }}
          id="detail-container"
        >
          <div className="detail-title detail-title--left">
            <FormattedMessage id="attend.detail.mainHeader" defaultMessage="ATENDIMENTO" /> {attend.id}
          </div>
          <div className="detail-line" />
          <AttendHeader
            secondary={secondary}
            transmitterList={transmitterList}
            client={client}
            equipment={equipment}
            duration={duration}
            attendAudio={attendAudio}
            alarms={attend.events}
            canMakeExtension={canMakeExtension}
            openModalExtension={() => setShowExtensionCall(true)}
          />
          <AccordionSection
            label={<FormattedMessage id="attend.detail.detailsHeader" defaultMessage="DETALHES" />}
          >
            <AttendDetail
              client={client}
              secondary={secondary}
              transmitterList={transmitterList}
              alarms={attend.events}
              lang={props.lang}
              showIcons={props?.configurationGlobal.show_icons}
            />
          </AccordionSection>
          {(successAttendant || successContact) &&
            <AccordionSection
              label={<FormattedMessage id="attend.closed.resolvedBy" defaultMessage="RESOLVIDO POR" />}
            >
              <AttendSolvedBy
                attendant={successAttendant}
                contact={successContact}
              />
            </AccordionSection>
          }
          <AccordionSection
            label={<FormattedMessage id="attend.closed.contactedPersons" defaultMessage="PESSOAS CONTATADAS" />}
          >
            <AttendContacted
              contactedAttendants={contactedAttendants}
              contactedContacts={contactedContacts}
            />
          </AccordionSection>
          <AccordionSection
            label={<FormattedMessage id="attend.closed.infoHeader" defaultMessage="INFORMAÇÕES" />}
          >
            <AttendInformation
              open={attend.open_ts}
              attend={attend.attend_ts}
              close={attend.close_ts}
              aditionalAudioList={aditionalAudio}
              tagId={tagId}
              lang={props.lang}
              isEditing={editingTag}
              setTag={setTag}
              toggleEdit={() => setEditingTag(!editingTag)}
              updateTag={updateTag}
              isAdmin={props.isAdmin}
              clientMessages={clientMessages}
            />
          </AccordionSection>
          {!props?.configurationGlobal.remove_audio_files && attend?.aditional_audio.length > 0 &&
            <AccordionSection
              label={<FormattedMessage id="attend.detail.audioInfo" defaultMessage="INFORMAÇÔES DE ÁUDIOS EXTRAS" />}
            >
              <AttendAudioInformation aditionalAudio={attend.aditional_audio} />
            </AccordionSection>
          }
          {equipment.is_mobile &&
            <AccordionSection
              label={<FormattedMessage id="attend.detail.locationHeader" defaultMessage="LOCALIZAÇÃO" />}
            >
              <AttendMap
                attend={attend}
                client={client}
                pointList={pointList}
                loadLatestPoint={() => {}}
                errorComponent={errorComponent}
                lang={props.lang}
                isAttendOpen={false}
              />
            </AccordionSection>
          }
          <AccordionSection
            label={<FormattedMessage id="attend.detail.reportHeader" defaultMessage="COMENTÁRIOS" />}
          >
            <AttendComments
              report={report}
              isEditing={editingComments}
              toggleEdit={() => setEditingComments(!editingComments)}
              setReport={(ev) => setReport(ev.target.value)}
              updateReport={updateReport}
              isAdmin={props.isAdmin}
            />
          </AccordionSection>
          <AccordionSection
            label={<FormattedMessage id="attend.closed.remindersHeader" defaultMessage="LEMBRETES" />}
          >
            <AttendReminders
              reminders={remindersList}
              reminderAudioList={reminderAudio}
            />
          </AccordionSection>
        </div>
      )}
    </div>
  );
};

AttendClosed.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
  loadContacts: PropTypes.func.isRequired,
  editAttend: PropTypes.func.isRequired,
  updateTag: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  configurationGlobal: PropTypes.shape({
    show_icons: PropTypes.bool,
    remove_audio_files: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    contactList: state.contacts,
    lang: state.locale.lang,
    configurationGlobal: state.globalConfiguration.global,
    isAdmin: state.loggedAttendant.groups
      ? (state.loggedAttendant.groups[0] === 1 || state.loggedAttendant.groups[0] === 4)
      : false,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editAttend: attend => dispatch(attendsActions.editAttend(attend)),
    updateTag: (attendId, tag) => dispatch(attendsActions.updateTag(attendId, tag)),
    loadContacts: (clientId, ordering) => dispatch(contactsActions.loadContacts(clientId, ordering)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendClosed);
