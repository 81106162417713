import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getDomain, host, getToken } from '../../../BLL/environment';
import * as general from '../../../BLL/general';
import * as contactsActions from '../../../actions/contactsActions';
import messages from '../../../BLL/locale/messages';

import '../../../css/middle.scss';
import '../../../css/table.scss';
 
import '../../../css/detailPage.scss';
import '../../../css/block.scss';

const ContactDetail = (props) => {
  const dispatch = useDispatch();
  const { contacts, attendants, isSysUser, globalConfiguration} = useSelector(
    (state: RootStateOrAny) => ({
      contacts: state.contacts,
      attendants: state.attendants,
      globalConfiguration: state.globalConfiguration.global,
      isSysUser: state.loggedAttendant.groups.length === 1
    })
  );

  const [contact, setContact] = useState(undefined);
  const [client, setClient] = useState(undefined);

  useEffect(() => {
    const setUpContact = (contacts) => {
      const { clientId, contactId } = props.match.params;
      const contactList = [...contacts.filter(c => c.clientId === clientId)[0].data];
      const contact = Object.assign({}, contactList.filter(con => con.id === parseInt(contactId, 10))[0]);
      setContact(contact);
    }

    const { clientId } = props.match.params;
    loadClient(parseInt(clientId, 10));
    if (contacts.length <= 0) {
      dispatch(contactsActions.loadContacts(clientId));
    } else {
      setUpContact(contacts)
    }
  }, [contacts, dispatch, props.match.params]);

  const loadClient = (clientId) => {
    axios.get(`https://${getDomain()}${host()}/api/clients/${clientId}/`, {
      headers: { Authorization: `Token ${getToken()}` },
    }).then(res => setClient(Object.assign({}, res.data)))
    .catch(err => console.log(err));
  };

  let actionName = [];
  if (localStorage.contacttoLang) {
    actionName = [
      messages[localStorage.contacttoLang]['general.highPriority'],
      messages[localStorage.contacttoLang]['general.mediumPriority'],
      messages[localStorage.contacttoLang]['general.lowPriority'],
      messages[localStorage.contacttoLang]['general.sysPriority'],
    ];
  } else {
    actionName = ['Alta Prioridade', 'Média Prioridade', 'Baixa Prioridade', 'Alarme de sistema'];
  }

  const creator = contact ?
    attendants[0]?.data?.filter(attendant => attendant.id === contact.created_by)[0] :
    undefined;
  const modifier = contact ?
    attendants[0]?.data?.filter(attendant => attendant.id === contact.modified_by)[0] :
    undefined;

  const primaryPhone = contact ?
    contact.phones.length >= 1 && contact.phones.find(phone => phone.phone_type === 'Primary') :
    undefined;
  
  console.log(contact)

  const secondaryPhones = contact ? contact.phones
    .filter(phone => phone.phone_type !== 'Primary')
    .sort((a, b) => a.id > b.id) : undefined;

  return (
    <div className="contactto-middle-content">
      <div className="detail-container">
        {client && <div className="detail-title"><FormattedMessage id="contact.detail.mainHeader" defaultMessage="VISUALIZAR INFORMAÇÕES DO CONTATO DE" /> <span className="grey">{`${client.first_name} ${client.last_name}`.toUpperCase()}</span></div>}
        {contact &&
          <div className="detail-info">
            <div className="block">
              <div className="block-title"><FormattedMessage id="contact.form.personal" defaultMessage="PESSOAL" /></div>
              <div className="col-12 row" style={{ padding: '1rem 0' }}>
                {client &&
                  <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                    <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.account" defaultMessage="Conta" /> </div>
                    <div className="col-sm-12 col-md-8 block-value">{client.account}</div>
                  </div>

                }
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.priority" defaultMessage="Prioridade" /> </div>
                  <div className="col-sm-12 col-md-8 block-value">{contact.priority}</div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="general.firstName" defaultMessage="Nome" /> </div>
                  <div className="col-sm-12 col-md-8 block-value">{contact.first_name}</div>
                </div>
                <div className="col-sm-12 col-md-6 " style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="general.lastName" defaultMessage="Sobrenome" /> </div>
                  <div className="col-sm-12 col-md-8 block-value">{contact.last_name}</div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.relationship" defaultMessage="Relação" /> </div>
                  <div className="col-sm-12 col-md-8 block-value">{contact.relationship}</div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.hasKeys" defaultMessage="Possui chaves" /> </div>
                  <div className="col-sm-12 col-md-8 block-value">{contact.has_keys ? <FormattedMessage id="general.yes" defaultMessage="Sim" /> : <FormattedMessage id="general.no" defaultMessage="Não" />}</div>
                </div>
                <div className="col-sm-12 col-md-6 " style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field">Email </div>
                  <div className="col-sm-12 col-md-8 block-value">{contact.email}</div>
                </div>
                <div className="col-sm-12 col-md-6 " style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.isContractor" defaultMessage="Contratante do Serviço" /> </div>
                  <div className="col-sm-12 col-md-8 block-value">{contact.is_contractor ? <FormattedMessage id="general.yes" defaultMessage="Sim" /> : <FormattedMessage id="general.no" defaultMessage="Não" />}</div>
                </div>
                <div className="col-sm-12 col-md-6 " style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.isFamily" defaultMessage="Familiar Administrador" /> </div>
                  <div className="col-sm-12 col-md-8 block-value">{contact.is_family ? <FormattedMessage id="general.yes" defaultMessage="Sim" /> : <FormattedMessage id="general.no" defaultMessage="Não" />}</div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}></div>
              </div>
            </div>

            <div className="block">
              <div className="block-title"><FormattedMessage id="contact.form.contactInfo" defaultMessage="CONTATO" /></div>
              <div className="col-12 row" style={{ padding: '1rem 0' }}>
                <div className="col-sm-12" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-2  block-field">
                    <FormattedMessage id="contact.form.street" defaultMessage="Logradouro" /> </div>
                  <div className="col-sm-12 col-md-10 block-value">{contact.street ? contact.street : '-'}</div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.number" defaultMessage="Número" /> </div>
                  <div className="col-sm-12 col-md-8 block-value">{contact.number === 0 ? '-' : contact.number }</div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.complement" defaultMessage="Complemento" /> </div>
                  <div className="col-sm-12 col-md-8 block-value">{contact.complement ? contact.complement : '-'}</div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.district" defaultMessage="Bairro" /> </div>
                  <div className="col-sm-12 col-md-8 block-value">{contact.district ? contact.district : '-'}</div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.zipCode" defaultMessage="CEP" /> </div>
                  <div className="col-sm-12 col-md-8 block-value">{contact.zip_code ? general.setMask(contact.zip_code, '00000-000') : '-'}</div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.city" defaultMessage="Cidade" /> </div>
                  <div className="col-sm-12 col-md-8 block-value">{contact.city ? contact.city : '-'}</div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.state" defaultMessage="Estado" /> </div>
                  <div className="col-sm-12 col-md-8 block-value">{contact.state ? contact.state : '-'}</div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.country" defaultMessage="País" /> </div>
                  <div className="col-sm-12 col-md-8 block-value">{contact.country ? contact.country : '-'}</div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}></div>
                {primaryPhone &&
                  <div className="col-sm-12 row">
                    <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                      <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.phone" defaultMessage="Telefone" /> </div>
                      <div className="col-sm-12 col-md-8 block-value">
                        {`${primaryPhone.country_code ? primaryPhone.country_code : "+55"}
                        ${general.renderPhoneDigit(primaryPhone.phone, contact.country)}`}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                      <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.type" defaultMessage="Tipo" /> </div>
                      <div className="col-sm-12 col-md-8 block-value">{general.phonePT(primaryPhone.phone_type)}</div>
                    </div>
                  </div>
                }
                {secondaryPhones
                .sort((phoneA, phoneB) => phoneA.id > phoneB.id)
                .map(phone =>
                  (
                    <div key={phone.id} className="col-sm-12 row">
                      <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                        <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.phone" defaultMessage="Telefone" /> </div>
                        <div className="col-sm-12 col-md-8 block-value">
                          {`${phone.country_code ? phone.country_code : "+55"} ${general.renderPhoneDigit(phone.phone, contact.country)}`}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                        <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="contact.form.type" defaultMessage="Tipo" /> </div>
                        <div className="col-sm-12 col-md-8 block-value">{general.phonePT(phone.phone_type)}</div>
                      </div>
                    </div>
                  ),
                )}
              </div>
            </div>

            <div className="block">
              <div className="block-title">
                <FormattedMessage id="general.actionsHeader" defaultMessage="AÇÕES" />
              </div>
              <div className="col-12 row" style={{ flexWrap: 'nowrap', padding: '1rem 0' }}>
                <div className="col-sm-6 col-md-2 block-field" />
                <div className="col-sm-6 col-md-10 row" style={{ flexWrap: 'nowrap' }}>
                  <div className="col-sm-4 grey">Email</div>
                  <div className="col-sm-4 grey">SMS</div>
                  {isSysUser &&  globalConfiguration?.can_use_whatsapp &&
                    <div className="col-sm-4 grey">WhatsApp</div>
                  }
                </div>
              </div>

              {contact.actions
              .sort((actionA, actionB) => actionA.priority - actionB.priority)
              .map(action =>
                (
                  <div key={actionName[action.priority - 1]} className="col-sm-12 row" style={{ flexWrap: 'nowrap'}}>
                    <div className="left col-sm-6 col-md-2 block-field">{actionName[action.priority - 1]}</div>
                    <div className="left col-sm-6 col-md-10 row" style={{ flexWrap: 'nowrap', paddingBottom: '1rem' }}>
                      <div className="col-sm-4">{action.receive_email ? <FormattedMessage id="general.yes" defaultMessage="Sim" /> : <FormattedMessage id="general.no" defaultMessage="Não" />}</div>
                      <div className="col-sm-4">{action.receive_sms ? <FormattedMessage id="general.yes" defaultMessage="Sim" /> : <FormattedMessage id="general.no" defaultMessage="Não" />}</div>
                      {isSysUser &&  globalConfiguration?.can_use_whatsapp &&
                        <div className="col-sm-4">{action.receive_whatsapp ? <FormattedMessage id="general.yes" defaultMessage="Sim" /> : <FormattedMessage id="general.no" defaultMessage="Não" />}</div>
                      }
                    </div>
                  </div>

                ),
              )}

            </div>
            <div className="block" style={{marginTop: '1rem'}}>
              <div className="block-title">LOGS</div>
              <div className="col-12 row" style={{ padding: '1rem 0' }}>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="logs.createdDate" defaultMessage="Data de criação" /> </div>
                  <div className="col-sm-12 col-md-6 block-value">{contact.created_date ? general.formatOnlyNumbersDate(contact.created_date) : '-'}</div>
                </div>
                <div className="col-sm-12 col-md-4" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-6 block-field"><FormattedMessage id="logs.createdBy" defaultMessage="Criado por" /> </div>
                  {creator ? <div className="col-sm-12 col-md-6 block-value">{`${creator.first_name} ${creator.last_name}`}</div> : <div className="col-sm-12 col-md-6 block-value"> - </div>}
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field"><FormattedMessage id="logs.modifiedDate" defaultMessage="Data de modificação" /> </div>
                  <div className="col-sm-12 col-md-6 block-value">{contact.modified_date ? general.formatOnlyNumbersDate(contact.modified_date) : '-'}</div>
                </div>
                <div className="col-sm-12 col-md-4" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-6 block-field"><FormattedMessage id="logs.modifiedBy" defaultMessage="Modificado por" /> </div>{modifier ? <div className="col-sm-12 col-md-6 block-value">{`${modifier.first_name} ${modifier.last_name}`}</div> : <div className="col-sm-12 col-md-6 block-value"> - </div>}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

ContactDetail.propTypes = {
  params: PropTypes.shape({
    clientId: PropTypes.string.isRequired,
    contactId: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContactDetail;
