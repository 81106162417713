import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import AudioMenu from '../Common/AudioMenu';
import { FormattedMessage } from 'react-intl';
import style from './modalReturnCall.module.scss';

const IDLE = 1;
const FIRST_CALL = 2;
const FIRST_HANGUP = 3;
const SECOND_CALL = 4;
const SEND_PASSWORD = 5;
const READY_FOR_PASSWORD_OR_SECOND_CALL = 6;
const WARNING_TIMER_LIMIT = 75
// const ERROR = -1;

const ModalPERS = (props) => {
  const [callStage, setCallStage] = useState(IDLE);
  const [durationFirstCall, setDurationFirstCall] = useState(12);
  const [durationLeft, setDuration] = useState(10);
  const [warningTimer, setWarningTimer] = useState(WARNING_TIMER_LIMIT);
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  useEffect(() => {
    if (callStage === FIRST_CALL) {
      if (!durationFirstCall) {
        setCallStage(READY_FOR_PASSWORD_OR_SECOND_CALL);
        return;
      }
      const intervalId = setInterval(() => {
        setDurationFirstCall((prev) => prev -1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [durationFirstCall, callStage]);

  useEffect(() => {
    if (callStage === FIRST_HANGUP) {
      if (!durationLeft) {
        makeSecondCall();
        return;
      }
      const intervalId = setInterval(() => {
        setDuration(durationLeft - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [durationLeft, callStage]);

  useEffect(() => {
    let intervalId
    if (callStage === SEND_PASSWORD) {
      intervalId = setInterval(() => {
        if (warningTimer <= 1) {
          clearInterval(intervalId);
          setShowWarningMessage(true);
        }
        setWarningTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [warningTimer, callStage]);

  const makeFirstCall = () => {
    setCallStage(FIRST_CALL);
    props.startCall(`${props.clientPhone}${props.clientAccount}pers`);
  };

  const makeFirstHangup = () => {
    setCallStage(FIRST_HANGUP);
    props.hangupSip();
  };

  const makeSecondCall = () => {
    setCallStage(SECOND_CALL);
    if (props.isIndependent) {
      props.startCall(`${props.clientPhone}${props.clientAccount}scall`);
    } else {
      props.startCall(`${props.clientPhone}${props.clientAccount}call${props.attendId}`);
    }
  };

  const sendPassword = () => {
    setCallStage(SEND_PASSWORD);
    props.sendPERSPassword();
  };

  const closeCall = () => {
    setCallStage(IDLE);
    props.hangupSip();
    props.closeModal();
  };

  const extendCall = () => {
    setWarningTimer(WARNING_TIMER_LIMIT);
    setShowWarningMessage(false);
    props.extendCall();
  }

  const closeCallAndStartOver = () => {
    setCallStage(IDLE);
    props.hangupSip();
  }

  return (
    <div className={style['modal-middle-wrapper']}>
      {callStage === IDLE &&
        <div className={style['modal-middle-container']}>
          <span style={{ fontWeight: '600' }}>
            <FormattedMessage id="return.makeCallLabel" defaultMessage="Retorno ao usuário" />
          </span>
          <span>
            <FormattedMessage id="return.toMakePersCall" defaultMessage="Para realizar a ligação ao dispositivo PERS:" />
          </span>
          <span style={{ textAlign: 'left', width: '100%' }}>
            1. <FormattedMessage id="return.toMakePersCallItem1" defaultMessage="Aguarde 3 toques de telefone comum"/>
          </span>
          <span style={{ textAlign: 'left', width: '100%' }}>
            2. <FormattedMessage id="return.toMakePersCallItem2" defaultMessage="Reinicie a ligação selecionando 'NÃO' na próxima etapa"/>
          </span>
          <span>
            <FormattedMessage id="return.toMakePersCallWarning" defaultMessage="Se o tom contínuo de máquina começar antes da segunda ligação, selecione 'SIM' na próxima etapa"/>
          </span>
          <button type="button" className={style['return-call-button']} onClick={makeFirstCall}>
            <FormattedMessage id="return.makeCallButton" defaultMessage="Realizar Ligação"/>
          </button>
        </div>
      }
      {callStage === FIRST_CALL &&
        <div className={style['modal-middle-container']}>
          <FormattedMessage
            id="return.waitForCallOrTone"
            defaultMessage="Realizando primeira ligação, aguarde..."
          />
          <span>
            <FormattedMessage
              id="return.waitForCallOrToneDuration"
              defaultMessage="Aguardando {duration} segundos..."
              values={{ duration: durationFirstCall }}
            />
          </span>
        </div>
      }
      {callStage === READY_FOR_PASSWORD_OR_SECOND_CALL && (
        <div className={style['modal-middle-container']}>
          <FormattedMessage
            id="return.toneReady"
            defaultMessage="O tom contínuo de máquina foi detectado? Escolha uma ação."
          />
          <span>
            <FormattedMessage
              id="return.toneReadyWarning"
              defaultMessage="Caso o tom ainda esteja tocando, aguardar finalização e clicar em Sim."
            />
          </span>
          <button type="button" className={style['return-call-button']} onClick={sendPassword}>
            <FormattedMessage id="general.yes" defaultMessage="Sim" />
          </button>
          <button type="button" className={style['return-call-button']} onClick={makeFirstHangup}>
            <FormattedMessage id="general.no" defaultMessage="Não" />
          </button>
        </div>
      )}
      {callStage === FIRST_HANGUP &&
        <div className={style['modal-middle-container']}>
          <FormattedMessage
            id="return.waitSecondLabel"
            defaultMessage="Realizando segunda ligação para o dispositivo."
          />
          <span>
            <FormattedMessage
              id="return.waitSeconds"
              defaultMessage="Aguardando {duration} segundos..."
              values={{ duration: durationLeft }}
            />
          </span>
        </div>
      }
      {callStage === SECOND_CALL &&
        <div className={style['modal-middle-container']}>
          <FormattedMessage id="return.waitToneLabel" defaultMessage="Aguarde o fim do tom" />
          <button type="button" className={style['return-call-button']} onClick={sendPassword}>
            <FormattedMessage id="return.sendPasswordButton" defaultMessage="Enviar senha" />
          </button>
        </div>
      }
      {callStage === SEND_PASSWORD &&
        <div className={style['modal-middle-container']}>
          <FormattedMessage id="return.ongoingCallLabel" defaultMessage="Chamada em andamento" />
          <AudioMenu muteAudio={props.muteAudio} mutePhone={props.mutePhone} autoCall={props.autoCall}/>
          <button type="button" className={style['return-call-button']} onClick={props.sendPERSPassword}>
            <FormattedMessage id="return.reSendPasswordButton" defaultMessage="ENVIAR SENHA NOVAMENTE" />
          </button>
          <button type="button" className={style['return-call-button']} onClick={closeCall}>
            <FormattedMessage id="return.hangupCall" defaultMessage="DESLIGAR" />
          </button>
          {showWarningMessage &&
            <div>
              <FormattedMessage
                id="return.extendCallWarning"
                defaultMessage="Clique no botão 'Estender Chamada' após ouvir o bip"
              />
            </div>
          }
          <button
            type="button"
            disabled={!showWarningMessage}
            className={`${style['return-call-button']} ${!showWarningMessage ? style['button-disabled'] : ""}`}
            onClick={extendCall}
          >
            <FormattedMessage id="return.extendCall" defaultMessage="ESTENDER CHAMADA" />
          </button>
        </div>
      }
    </div>
  )
};

const ModalClimax = (props) => {
  const [callStage, setCallStage] = useState(IDLE);

  const makeCall = () => {
    setCallStage(FIRST_CALL);
    if (props.isIndependent) {
      props.startCall(`005491${props.clientAccount}${props.clientPhone}scall`);
    } else {
      props.startCall(`005491${props.clientAccount}${props.clientPhone}call${props.attendId}`);
    }
  };

  const closeCall = () => {
    setCallStage(IDLE);
    props.hangupSip();
    props.closeModal();
  };

  console.log(props);

  return (
    <div className={style['modal-middle-wrapper']}>
      {callStage === IDLE &&
        <div className={style['modal-middle-container']}>
          <FormattedMessage id="return.makeCallLabel" defaultMessage="Retorno ao usuário" />
          <button type="button" className={style['return-call-button']} onClick={makeCall}>
            <FormattedMessage id="return.makeCallButton" defaultMessage="Realizar Ligação"/>
          </button>
        </div>
      }
      {callStage === FIRST_CALL &&
        <div className={style['modal-middle-container']}>
          <FormattedMessage id="return.ongoingCallLabel" defaultMessage="Chamada em andamento" />
          <AudioMenu muteAudio={props.muteAudio} mutePhone={props.mutePhone} autoCall={props.autoCall}/>
          <button type="button" className={style['return-call-button']} onClick={closeCall}>
            <FormattedMessage id="return.hangupCall" defaultMessage="DESLIGAR" />
          </button>
        </div>
      }
    </div>
  );

}

const ModalReturnCall = (props) => {
  console.log('RETURN CALL', props);
  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;
  const wrapperRef = useRef(null);
  const [posX, setPosX] = useState(props.posX);
  const [posY, setPosY] = useState(props.posY);

  const dragMouseDown = (e) => {
    e.preventDefault();
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  };

  const elementDrag = (e) => {
    e.preventDefault();
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;

    setPosX(wrapperRef.current.offsetLeft - pos1);
    setPosY(wrapperRef.current.offsetTop - pos2);
  };

  const closeDragElement = () => {
    document.onmouseup = null;
    document.onmousemove = null;
  };
  return (
    <div className={style['return-container']} style={{ left: posX, top: posY }} ref={wrapperRef}>
      <div id="header" className={style['modal-header']} onMouseDown={dragMouseDown}>
        <button onClick={props.closeModal} className={style['return-close-modal-button']}>
          <i class="fas fa-times" />
        </button>
      </div>
      {props.equipment?.name.includes('PERS') &&
        <ModalPERS
          clientPhone={props.clientPhone}
          clientAccount={props.clientAccount}
          attendId={props.attendId}
          startCall={props.startCall}
          sendPERSPassword={props.sendPERSPassword}
          hangupSip={props.hangupSip}
          sipStackType={props.sipStackType}
          closeModal={props.closeModal}
          isIndependent={props.isIndependent}
          muteAudio={props.muteAudio}
          mutePhone={props.mutePhone}
          autoCall={props.autoCall}
          extendCall={props.extendCall}
        />
      }
      {(props.equipment?.name.toLowerCase().includes('climax') || props.equipment?.name.toLowerCase().includes('ev07b')) &&
        <ModalClimax
          clientPhone={props.clientPhone}
          clientAccount={props.clientAccount}
          attendId={props.attendId}
          startCall={props.startCall}
          hangupSip={props.hangupSip}
          sipStackType={props.sipStackType}
          closeModal={props.closeModal}
          isIndependent={props.isIndependent}
          muteAudio={props.muteAudio}
          mutePhone={props.mutePhone}
          autoCall={props.autoCall}
        />
      }
    </div>
  )
};

ModalReturnCall.defaultProps = {
  posX: 570,
  posY: 15,
  isIndependent: false,
};

ModalReturnCall.propTypes = {
  posX: PropTypes.number,
  posY: PropTypes.number,
  isIndependent: PropTypes.bool,
  equipment: PropTypes.shape({
    name: PropTypes.string,
    is_mobile: PropTypes.bool,
  }).isRequired,
};

export default ModalReturnCall;
