import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { generalPhoneNumber } from '../../../BLL/general';
import style from './reminderModal.module.scss';

import messages from '../../../BLL/locale/messages';

const ContactInfo = ({contact, index, lang}) => {
  const [expanded, setExpanded] = useState(false);

  return(
    <>
      <div id={`reminder-contact-${index}`} className={style['reminder-contacts-row']} onClick={() => setExpanded(!expanded)}>
        <span style={{ width: '50%' }}>{contact.first_name} {contact.last_name}</span>
        <span style={{width: '5%'}}>
          {expanded ? '-' : '+'}
        </span>
      </div>
      {expanded && contact.phone_list.length > 0 &&
        contact.phone_list.map((elm) => (
          <div className={style['reminder-contacts-expanded-row']}>
            <span style={{ width: '15%' }}></span>
            <span style={{width: '60%'}}>
              {`${elm.country_code ? elm.country_code : "+55"} ${generalPhoneNumber(elm.phone)}`} - {messages[lang][`contact.form.${elm.phone_type.toLowerCase()}`]}
            </span>
            <span style={{width: '5%'}}></span>
          </div>
        ))
      }
    </>
  );
};

ContactInfo.propTypes = {
  index: PropTypes.number.isRequired,
  lang: PropTypes.string.isRequired,
  contact: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone_list: PropTypes.arrayOf(PropTypes.shape({
      phone: PropTypes.string,
      phone_type: PropTypes.string
    }))
  }).isRequired,

}

export default ContactInfo;