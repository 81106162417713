import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from 'axios';
import { useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { host, getDomain, getToken } from "../../../../BLL/environment";
import { generateRandomNumberString } from "../../../../BLL/general";
import * as general from '../../../../BLL/general';
import styles from "./extensionCaller.module.scss";
import messages from "../../../../BLL/locale/messages";

const ExtensionCaller = ({ selectedLine, handleBackButton, startCall, hangupSip, clientAccount, attendId, callType, sipEventType }) => {
  const [callState, setCallState] = useState('idle');
  console.log('EVENT TYPE =====>', sipEventType);

  useEffect(() => {
    if (sipEventType === 'terminated') {
      setCallState('finished');
    }
  }, [sipEventType])

  const { lang, extensionId } = useSelector((state) => ({
    lang: state.locale.lang,
    extensionId: state.loggedAttendant.user_info.extension_id
  }));

  const padWithZero = (number) => number.toString().padStart(2, '0');

  const handleMakeCall = async (lineType=1) => {
    const typeNumbers = {
      externalLine1: '005494',
      reminderLine1: '005495',
      externalLine2: '005496',
      reminderLine2: '005497',
    }
    const phone = general.removeNonDigit(selectedLine.phone, true)
    const attendUid = generateRandomNumberString(6);
    const typeNumber = callType === 'attend'
      ? typeNumbers[`externalLine${lineType}`]
      : typeNumbers[`reminderLine${lineType}`];
    const callNumber = `${typeNumber}${clientAccount}${attendUid}${phone}`;
    setCallState('onCall');
    startCall(callNumber);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const file = `${clientAccount}-${phone}-${year}${padWithZero(month)}${padWithZero(day)}-${attendUid}${callType === 'reminder' ? '-REMINDER' : ''}.wav`;
    const timezoneOffsetInMs = currentDate.getTimezoneOffset() * 60 * 1000;
    const localDate = new Date(currentDate - timezoneOffsetInMs);
    const audio_ts = localDate.toISOString();

    const apiUrl = `https://${getDomain()}${host()}/api/attend/${attendId}/additional-audio/`;
    try {
      const response = await axios.post(apiUrl, {
        from_id: extensionId,
        to_id: phone,
        audio_file: `${year}/${month}/${day}/${file}`,
        audio_ts,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${getToken()}`,
        },
      });
      if (!response.status === 200) {
        console.error('Error posting additional audio data');
      }
    } catch (error) {
      console.error('Error posting additional audio data', error);
    }
  };

  const handleHangUp = () => {
    setCallState('finished');
    hangupSip();
  };

  const handleBackClick = () => {
    hangupSip();
    handleBackButton();
  }


  return (
    <div>
      {callState === 'idle' &&
        <>
          <div className={styles['container']}>
            <div>
              <FormattedMessage id="extension.makeCallLabel" defaultMessage="LIGANDO PARA" />
            </div>
            <div>
              <span>
                {messages[lang][`extension.${selectedLine.type}`]} - {selectedLine.name} - {
                  selectedLine.phone.length > 10 ? general.setMask(selectedLine.phone, '(00) 0 0000-0000') : general.setMask(selectedLine.phone, '(00) 0000-0000')
                }
              </span>
            </div>
            <div className={styles['btn-container']}>
              <div className={styles['btn-make-call-container']}>
                <button className={styles['make-call-btn']} onClick={() => handleMakeCall(1)}>
                  <FormattedMessage id="extension.makeCall" defaultMessage="REALIZAR LIGAÇÃO" />
                </button>
                {/* <button className={styles['make-call-btn']} onClick={() => handleMakeCall(2)}>
                  <FormattedMessage id="extension.makeCall" defaultMessage="REALIZAR LIGAÇÃO" />
                </button> */}
              </div>
              <button className={styles['back-btn']} onClick={handleBackClick}>
                <FormattedMessage id="extension.back" defaultMessage="VOLTAR" />
              </button>
            </div>
          </div>
        </>
      }
      {callState === 'onCall' &&
        <>
          <div className={styles['container']}>
            <FormattedMessage id="attend.open.calling" defaultMessage="Realizando ligação, aguarde um instante" />...
            <div>
              <FormattedMessage id="extension.makeCallLabel" defaultMessage="LIGANDO PARA" />
            </div>
            <div>
              <span>
                {messages[lang][`extension.${selectedLine.type}`]} - {selectedLine.name} - {
                  selectedLine.phone.length > 10 ? general.setMask(selectedLine.phone, '(00) 0 0000-0000') : general.setMask(selectedLine.phone, '(00) 0000-0000')
                }
              </span>
            </div>
            <button className={styles['back-btn']} onClick={() => handleHangUp()}>
              <FormattedMessage id="attend.open.hangUp" defaultMessage="DESLIGAR" />
            </button>
          </div>
        </>
      }
      {callState === 'finished' &&
        <>
          <div className={styles['container']}>
            <FormattedMessage id="attend.open.closedCall" defaultMessage="Chamada encerrada" />
            <button className={styles['back-btn']} onClick={handleBackClick}>
              <FormattedMessage id="extension.back" defaultMessage="VOLTAR" />
            </button>
          </div>
        </>
      }
    </div>
  )
};

ExtensionCaller.defaultProps = {
  callType: 'attend'
};

ExtensionCaller.propTypes = {
  handleBackButton: PropTypes.func.isRequired,
  startCall: PropTypes.func.isRequired,
  hangup: PropTypes.func.isRequired,
  sipEventType: PropTypes.string.isRequired,
  selectedLine: PropTypes.shape({
    type: PropTypes.string,
    phone: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  clientAccount: PropTypes.string.isRequired,
  attendId: PropTypes.number,
};

export default ExtensionCaller;
