import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import style from './managementMenu.module.scss';

const ManagementMenu = ({ loggedAttendant, administrators, managers, owner, history }) => {
  const url = window.location.href ? window.location.href : '';
  const isManager = (managers && loggedAttendant.groups.length === 1)
    ? managers.user_set?.some(mngr => mngr.id === loggedAttendant.id)
    : false;
  const isSysAdmin = (administrators && loggedAttendant.groups.length === 1)
    ? administrators.user_set?.some(adm => adm.id === loggedAttendant.id)
    : false;
  const isSysOwner = (owner && loggedAttendant.groups.length === 1)
    ? owner.user_set?.some(own => own.id === loggedAttendant.id)
    : false;
  return (
    <div className={style.wrapper}>
      <div className={style.list}>
        <ul className={style.ul}>
          { (isSysAdmin || isSysOwner || isManager) &&
            <li className={style.li}>
              <button
                className={`${style.button} ${url.indexOf('/manage/attend') !== -1 ? style.active : ''}`}
                onClick={() => { history.push('/app/manage/attend'); }}
              >
                <FormattedMessage id="management.attendantsOption" defaultMessage="ATENDENTES" />
              </button>
            </li>
          }
          {(isSysAdmin || isSysOwner || isManager) &&
            <li className={style.li}>
              <button
                style={{ width: '180px' }}
                className={`${style.button} ${url.indexOf('/manage/online-') !== -1 ? style.active : ''}`}
                onClick={() => { history.push('/app/manage/online-attendants'); }}
              >
                ATENDENTES ONLINE
              </button>
            </li>
          }
          <li className={style.li}>
            <button
              className={`${style.button} ${url.indexOf('/manage/alarm') !== -1 ? style.active : ''}`}
              onClick={() => { history.push('/app/manage/alarms'); }}
            >
              <FormattedMessage id="management.alarmsOption" defaultMessage="ALARMES" />
            </button>
          </li>
          <li className={style.li}>
            <button
              className={`${style.button} ${url.indexOf('/manage/compani') !== -1 ? style.active : ''}`}
              onClick={() => { history.push('/app/manage/companies'); }}
            >
              <FormattedMessage id="management.companiesOption" defaultMessage="EMPRESAS" />
            </button>
          </li>
          <li className={style.li}>
            <button
              className={`${style.button} ${url.indexOf('/manage/tracker') !== -1 ? style.active : ''}`}
              onClick={() => { history.push('/app/manage/trackerlist'); }}
            >
              <FormattedMessage id="management.trackersOption" defaultMessage="RASTREADORES" />
            </button>
          </li>
          <li className={style.li}>
            <button
              className={`${style.button} ${url.indexOf('/manage/ata') !== -1 ? style.active : ''}`}
              onClick={() => { history.push('/app/manage/ataList'); }}
            >
              ATAS
            </button>
          </li>
          <li className={style.li}>
            <button
              style={{ width: '180px' }}
              className={`${style.button} ${url.indexOf('/manage/client-location') !== -1 ? style.active : ''}`}
              onClick={() => { history.push('/app/manage/client-location'); }}
            >
              MAPA USUÁRIOS
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

ManagementMenu.propTypes = {
  loggedAttendant: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    groups: PropTypes.arrayOf(
      PropTypes.number,
    ),
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
  }).isRequired,
  administrators: PropTypes.shape({
    id: PropTypes.number,
    is_active: PropTypes.bool,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    groups: PropTypes.arrayOf(
      PropTypes.number,
    ),
  }).isRequired,
  managers: PropTypes.shape({
    id: PropTypes.number,
    is_active: PropTypes.bool,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    groups: PropTypes.arrayOf(
      PropTypes.number,
    ),
  }).isRequired,
  owner: PropTypes.shape({
    id: PropTypes.number,
    is_active: PropTypes.bool,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    groups: PropTypes.arrayOf(
      PropTypes.number,
    ),
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    loggedAttendant: state.loggedAttendant,
    groups: state.groups,
    administrators: state.groups.length > 0 ? state.groups.find(grp => grp.id === 1) : [],
    managers: state.groups.length > 0 ? state.groups.find(grp => grp.id === 2) : [],
    owner: state.groups.length > 0 ? state.groups.find(grp => grp.id === 4) : [],
  };
}

export default connect(mapStateToProps)(ManagementMenu);
